var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"shipmentValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('br'),_c('h2',{staticClass:"shipment_create_header"},[_vm._v("Shipment details")]),_c('br'),_c('br'),_c('b-card',[_c('b-container',[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Invoice number*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Invoice number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter invoice number"},model:{value:(_vm.form.invoice_no),callback:function ($$v) {_vm.$set(_vm.form, "invoice_no", $$v)},expression:"form.invoice_no"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Country","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":""},model:{value:(_vm.country.name),callback:function ($$v) {_vm.$set(_vm.country, "name", $$v)},expression:"country.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"pt-2",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Buyer","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Buyer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":""},model:{value:(_vm.buyer.code),callback:function ($$v) {_vm.$set(_vm.buyer, "code", $$v)},expression:"buyer.code"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"pt-2",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Flight*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Flight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter flight number"},model:{value:(_vm.form.flight),callback:function ($$v) {_vm.$set(_vm.form, "flight", $$v)},expression:"form.flight"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"pt-2",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"ETA*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"ETA"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"placeholder":"Select Date"},model:{value:(_vm.form.eta),callback:function ($$v) {_vm.$set(_vm.form, "eta", $$v)},expression:"form.eta"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"pt-2",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"AWB*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"AWB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter AWB number"},model:{value:(_vm.form.awb),callback:function ($$v) {_vm.$set(_vm.form, "awb", $$v)},expression:"form.awb"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"pt-2",attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Consignee*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Consignee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Enter Consignee"},model:{value:(_vm.form.consignee),callback:function ($$v) {_vm.$set(_vm.form, "consignee", $$v)},expression:"form.consignee"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"pt-2",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Usd Converting Rate*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Usd Converting Rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter Usd Converting Rate"},model:{value:(_vm.form.buyer_converting_rate),callback:function ($$v) {_vm.$set(_vm.form, "buyer_converting_rate", $$v)},expression:"form.buyer_converting_rate"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('br')],1)],1)],1),_c('div',{staticClass:"pt-2"}),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}}),_c('b-col',{staticClass:"text-right",attrs:{"lg":"6"}},[_c('b-button',{staticClass:"form_submit_button",attrs:{"variant":"none","disabled":invalid}},[_c('span',{staticClass:"button_text_styles",on:{"click":function($event){return _vm.next()}}},[_vm._v("Next")])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }